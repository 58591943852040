@import "https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
* {
  box-sizing: border-box;
}

body, html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  background-color: #000;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Catamaran, sans-serif;
  font-size: 1.125rem;
  font-weight: 200;
}

img {
  max-width: 100%;
  display: inline-block;
}

i {
  color: #fff;
  font-size: 26px;
}

a, a:visited, a:hover {
  color: #000;
  text-decoration: none;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.navbar {
  z-index: 1000;
  background-color: #000;
  width: 100%;
  margin: 0 auto;
  padding: .5rem 0;
  transition: transform .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar .container {
  width: 97.5%;
  max-width: 1450px;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__links, header .button-link {
  display: none;
}

.logo {
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.hamburger {
  cursor: pointer;
}

.hamburger > span {
  transform-origin: 3px 1px;
  background-color: #fff;
  width: 26px;
  height: 2px;
  transition: all .3s;
  display: block;
}

.hamburger > span:not(:first-child) {
  margin-top: 5px;
}

.navbar.open .hamburger > span:first-child {
  transform: rotate(45deg);
}

.navbar.open .hamburger > span:nth-child(2) {
  opacity: 0;
}

.navbar.open .hamburger > span:last-child {
  transform: rotate(-45deg);
}

.overlay {
  z-index: 2;
  background-image: linear-gradient(#000, #0000);
  position: fixed;
  inset: 0;
}

.header__menu {
  color: #000;
  z-index: 3;
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: .75rem;
  width: calc(100% - 2.75rem);
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

.fade-in {
  animation: .25s forwards fade-in;
}

.fade-out {
  animation: .25s forwards fade-out;
}

.lock-scroll {
  overflow: hidden !important;
}

.entry-text {
  opacity: .7;
  margin: 20px 0 11px;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.title {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#f1f1ef, #f1f1ef4d);
  background-clip: text;
  margin: 0;
  font-family: Raleway, sans-serif;
  font-size: 40px;
  font-style: italic;
}

.title span {
  font-style: normal;
  font-weight: 700;
}

.heading {
  text-align: center;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#f1f1ef, #f1f1ef4d);
  background-clip: text;
  margin: 0;
  font-family: Raleway, sans-serif;
  font-size: 38px;
  font-style: italic;
}

.heading span {
  font-style: normal;
  font-weight: 700;
}

.background {
  display: none;
}

.get-started {
  color: #e84e50;
  cursor: pointer;
  background: none;
  border: 1px solid #f1f1ef99;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  margin: .5em;
  padding: .5em 1.2em;
  font-size: 16px;
  line-height: 1;
  transition: all .25s;
  display: flex;
}

.get-started:hover {
  border-color: #e84e50;
  transform: translateY(-.25em);
  box-shadow: 0 .5em .5em -.4em #e84e50;
}

.marquee {
  block-size: var(--marquee-item-height);
  margin: 2.5rem auto;
  display: flex;
  position: relative;
  overflow-x: hidden;
  mask-image: linear-gradient(to right, #0000, #000 20% 80%, #0000);
}

.marquee img {
  height: 50px;
}

.marquee--3 {
  --marquee-item-width: 50px;
  --marquee-item-height: 50px;
  --marquee-duration: 20s;
  --marquee-items: 3;
}

.marquee__item {
  --marquee-item-offset: max(calc(var(--marquee-item-width) * var(--marquee-items)), calc(100% + var(--marquee-item-width)));
  --marquee-delay: calc(var(--marquee-duration) / var(--marquee-items) * (var(--marquee-items)  - var(--marquee-item-index)) * -1);
  animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;
  position: absolute;
  inset-inline-start: var(--marquee-item-offset);
  transform: translateX(-50%);
}

.marquee--3 .marquee__item:first-of-type {
  --marquee-item-index: 1;
}

.marquee--3 .marquee__item:nth-of-type(2) {
  --marquee-item-index: 2;
}

.marquee--3 .marquee__item:nth-of-type(3) {
  --marquee-item-index: 3;
}

@keyframes go {
  to {
    inset-inline-start: calc(var(--marquee-item-width) * -1);
  }
}

#home .main-content {
  z-index: 1;
  color: #f1f1ef99;
  background: url("Mask group (2).a9743778.png") no-repeat;
  position: relative;
}

#home .main-content .landing {
  grid-template-columns: 1fr;
  align-content: space-evenly;
  place-items: center;
  gap: 0;
  width: 90%;
  max-width: 1200px;
  height: calc(100vh - 58px);
  margin: 4rem auto 0;
  display: grid;
}

#home .main-content .description {
  background: none;
  width: 90%;
  max-width: 1200px;
  min-height: calc(90vh - 58px);
  margin: 58px auto 0;
}

#home .main-content .description .grid-container {
  grid-template: "description" 1fr
                 "logo" 1fr
                 / 1fr;
  align-items: center;
  gap: 1.5rem;
  height: 70vh;
  padding-top: 2rem;
  display: grid;
}

#home .main-content .description .grid-container .text {
  grid-area: description;
  font-size: 16px;
}

#pricing .main-content {
  z-index: 1;
  color: #f1f1ef99;
  text-align: center;
  width: 95%;
  position: relative;
}

#pricing .main-content .text {
  font-size: 18px;
}

#pricing .main-content .container {
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin: 4rem auto 3rem;
  display: grid;
}

#pricing .main-content .container:before {
  content: " ";
  opacity: .6;
  z-index: -1;
  background: url("Asset 1-8.8502bf53.png") 0 0 / contain no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

#pricing .main-content .container .child {
  text-align: left;
  background: url("card.b6131c6a.svg");
  border: 1px solid #e84e5026;
  border-radius: 20px;
  padding: 2rem 1rem;
}

#pricing .main-content .container .child .flex {
  justify-content: left;
  gap: 1rem;
  display: flex;
}

#pricing .main-content .container .child .flex p {
  margin: .5rem 0;
}

#pricing .backgrounds {
  opacity: .12;
  width: 25%;
  position: absolute;
  top: 0;
}

#pricing .backgrounds:nth-of-type(2) {
  right: 0;
}

#services {
  min-height: 100vh;
}

#services .services {
  background: none;
  width: 100%;
  max-width: 1200px;
  margin: 58px auto 0;
}

#services .services .flex-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  width: 95%;
  margin: 3rem auto;
  display: flex;
}

#services .services .card {
  box-shadow: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  border: 1px solid #ffffff80;
  border-radius: 1.5rem;
  width: calc(33.3333% - 4rem);
  min-width: 350px;
  max-width: 450px;
  height: 200px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

#services .services .card:hover {
  border-color: #e84e50;
  transform: translateY(-3px);
  box-shadow: 4px 4px 8px #e84e50;
}

#services .services .card .card-content {
  margin: 1rem;
}

#services .services .card .more {
  font-size: 16px;
}

#services .services .card .sub-title {
  color: #e84e50;
  font-size: 20px;
  font-weight: 600;
}

#services .services .icons {
  width: 40px;
  height: 40px;
}

#abouts {
  margin: 4rem 0;
}

#abouts .container {
  color: #fff;
  place-items: center;
  width: 90%;
  margin: 0 auto;
  display: grid;
  position: relative;
}

#abouts .container .box {
  margin: 2rem auto;
}

#abouts .container .box .images {
  width: 100%;
}

#abouts .container .box .heading {
  margin-bottom: 1.5rem;
}

#abouts .container:nth-of-type(2) {
  grid-template: "description" 1fr
                 "image"
                 / 1fr;
}

#abouts .container:nth-of-type(2) .box:first-of-type {
  grid-area: image;
}

#abouts .text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

#call-to-action {
  background-image: url("call.21705355.svg"), linear-gradient(#1f2321, #020b05);
  background-position: 45% 0;
  background-repeat: no-repeat;
  border-radius: 25px;
  width: 80%;
  height: 75vh;
  margin: 3rem auto;
  position: relative;
}

#call-to-action .center {
  place-items: center;
  gap: 1.25rem;
  font-size: 18px;
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#call-to-action .background {
  object-fit: contain;
  object-position: 50% bottom;
  border-radius: 25px;
  height: 75vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

footer .footer-content {
  color: #fff;
  padding: 0 5% 1rem;
  font-size: 17px;
}

footer .footer-content .category {
  font-size: 24px;
}

footer .footer-content .container {
  grid-template-columns: 1fr;
  justify-items: start;
  width: 80%;
  margin: 0 auto;
  display: grid;
}

footer .footer-content .column {
  min-width: 245px;
}

footer .footer-content .navigation {
  text-align: left;
  width: 100%;
  padding-left: 0;
  list-style: none;
}

footer .footer-content .navigation a, footer .footer-content .navigation a:visited, footer .footer-content .navigation a:active {
  color: #ffffffe6;
  transition: color .2s;
}

footer .footer-content .navigation a:hover, footer .footer-content .navigation a:visited:hover, footer .footer-content .navigation a:active:hover {
  color: #e84e50;
}

footer .footer-content .navigation li {
  justify-content: left;
  align-items: center;
  gap: 1rem;
  margin: 0 auto .75rem;
  display: flex;
}

footer .footer-content .navigation li img {
  width: 26px;
  height: 26px;
  display: block;
}

footer .footer-content .fieldInput {
  background-color: #fffffff2;
  border: 1px solid #fff3;
  border-radius: 35px;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 3rem auto auto;
  padding-top: 0;
  padding-bottom: 0;
  transition: border-color .2s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 4px 4px 8px #000;
}

footer .footer-content .fieldInput:hover, footer .footer-content .fieldInput:focus-within {
  border: 1px solid #ffffff80;
}

footer .footer-content .fieldInput .form-input {
  background: none;
  border: none;
  padding: 0 2px;
}

footer .footer-content .fieldInput .form-input:focus {
  outline: none;
}

footer .footer-content .fieldInput .form-submit {
  cursor: pointer;
  color: #fff;
  background-color: #e84e50;
  border: 1px solid #fff3;
  border-radius: 100px;
  height: 38px;
  padding: 0 1.2rem;
  font-size: 12px;
  transition: all .2s;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

footer .footer-content .fieldInput .form-submit:hover {
  box-shadow: 0 .5em .5em -.4em #000;
}

footer .footer-content .social_profile {
  margin-top: 40px;
}

footer .footer-content .social_profile .list {
  justify-content: space-evenly;
  align-items: center;
  gap: .8rem;
  display: flex;
}

footer .footer-content .social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

footer .footer-content .social_profile ul li a {
  text-align: center;
  text-transform: uppercase;
  color: #e84e50;
  border: 1px solid #e84e504d;
  border-radius: 5rem;
  width: 50px;
  height: 50px;
  line-height: 3.2rem;
  transition: all .3s;
  display: block;
}

footer .footer-content .social_profile ul li a:hover {
  border: 0;
  box-shadow: 4px 4px 8px #e84e50;
}

footer .footer-content .copyright {
  color: #fff;
  text-align: center;
  opacity: .8;
  border-top: 1px solid #fff6;
  width: 87.5%;
  margin: 1rem auto 0;
  padding-top: 1.5rem;
  font-size: 16px;
}

footer .footer-content .copyright a {
  color: #fff;
  cursor: pointer;
  transition: all .2s;
}

footer .footer-content .copyright a:hover {
  color: #e84e50;
}

@media (width >= 768px) {
  .header__links {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: .875rem;
    transition: all .2s ease-in-out;
    display: flex;
    position: relative;
  }

  .header__links a {
    color: #ffffffa6;
    text-transform: uppercase;
    font-weight: 400;
    transition: all .15s linear;
    position: relative;
  }

  .header__links a:hover {
    color: #fff;
    transform: translateY(-2px);
  }

  .hamburger, .header__menu, .overlay {
    display: none;
  }

  header .button-link {
    cursor: pointer;
    color: #fff;
    background: #e84e50;
    border: none;
    border-radius: 50px;
    padding: .5em 1.5em;
    font-size: 15px;
    transition: all .2s;
    display: block;
  }

  header .button-link:hover {
    color: #fff;
    font-weight: normal;
    transform: translateY(-3px);
  }

  .logo {
    align-self: center;
    align-items: center;
    height: 100%;
    display: flex;
  }

  .nav__list {
    gap: 3rem;
    display: flex;
  }

  .background {
    opacity: .55;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  #home .main-content {
    color: #f1f1ef99;
    background: none;
  }

  #home .main-content .landing {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1.5rem;
    margin: 58px auto 0;
    display: grid;
  }

  #home .main-content .landing .hero-image {
    height: 80%;
  }

  #home .main-content .description .grid-container {
    grid-template: "logo description" 1fr
    / .8fr .75fr;
    gap: 2rem;
  }

  #pricing .main-content {
    color: #f1f1ef99;
    background: none;
    margin: 8rem auto 0;
  }

  #abouts .container {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    height: 65vh;
  }

  #abouts .container .box {
    padding: 3rem 2rem 0;
  }

  #abouts .container:first-of-type .images {
    width: 85%;
  }

  #abouts .container:nth-of-type(2) {
    grid-template-columns: .75fr 1fr;
    grid-template-areas: "image description";
    padding-bottom: 4rem;
  }

  #abouts .container:nth-of-type(3) {
    grid-template-columns: 1fr .75fr;
    padding-left: 6rem;
  }

  #abouts .container .background {
    object-fit: contain;
    width: calc(50% - 1.5rem);
    height: 70vh;
    position: absolute;
  }

  #abouts .container .background.first {
    left: calc(50% - 3rem);
  }

  #abouts .container .background.second {
    left: 3rem;
  }

  #call-to-action {
    border-radius: 75px;
  }

  #call-to-action .center {
    top: 45%;
  }

  #call-to-action .background {
    object-fit: cover;
    object-position: 50% 60%;
    border-radius: 75px;
  }

  footer .footer-content .container {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    width: 100%;
  }

  footer .footer-content .container .category {
    opacity: .85;
    width: 100%;
  }
}
/*# sourceMappingURL=index.6f4896ca.css.map */
