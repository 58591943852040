@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

$titleFont: 'Raleway', sans-serif;
$textFont: 'Catamaran', sans-serif;

$primary: #071c31;
$accent: #e84e50;
$secondary: #aa423f;


@mixin underline {
  content: "";
  position: absolute;
  display: block;
  height: 3px;
  width: 1.6rem;
  bottom: -15px;
  border-radius: 25px;
  background-color: black;
}

@mixin grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  align-items: center;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: $textFont;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  width: 100%;
  min-height: 100%;
  font-size: 1.125rem;
  font-weight: 200;
  background-color: black;
}

img {
  max-width: 100%;
  display: inline-block;
}

i {
  color: white;
  font-size: 26px;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: black;
}

//section {
//  height: 100vh;
//}

/*Lenis Styling*/
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/*Navbar Related Styling*/
.navbar {
  padding: .5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  margin: 0 auto;
  transition: transform 0.3s ease;
  background-color: black;

  .container {
    padding: 0 1.25rem;
    width: 97.5%;
    max-width: 1450px;
    margin: 0 auto;
  }
}

.navbar.hidden {
  transform: translateY(-100%);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__links {
  display: none;
}

header .button-link {
  display: none;
}

.logo {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger {
  cursor: pointer;
}

.hamburger>span {
  display: block;
  background-color: white;
  width: 26px;
  height: 2px;
  transform-origin: 3px 1px;
  transition: all 300ms ease;
}

.hamburger>span:not(:first-child) {
  margin-top: 5px;
}

.navbar.open .hamburger>span:first-child {
  transform: rotate(45deg);
}

.navbar.open .hamburger>span:nth-child(2) {
  opacity: 0;
}

.navbar.open .hamburger>span:last-child {
  transform: rotate(-45deg);
}

.overlay {
  position: fixed;
  inset: 0 0 0 0;
  z-index: 2;
  background-image: linear-gradient(black, transparent);
}

.header__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .75rem;
  padding: 2rem;
  border-radius: 5px;
  background: white;
  color: black;
  position: absolute;
  width: calc(100% - 2.75rem);
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2rem;
  z-index: 3;
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

.fade-in {
  animation: fade-in 0.25s ease forwards;
}

.fade-out {
  animation: fade-out 0.25s ease forwards;
}

.lock-scroll {
  overflow: hidden !important;
}

.entry-text {
  font-family: $titleFont;
  font-weight: 400;
  opacity: 0.7;
  font-size: 20px;
  margin: 20px 0 11px 0;
}

.title {
  font-family: $titleFont;
  font-size: 40px;
  font-style: italic;
  margin: 0;
  background-image: linear-gradient(to bottom, #f1f1ef, rgba(241, 241, 239, 0.3));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  span {
    font-weight: 700;
    font-style: normal;
  }
}

.heading {
  font-family: $titleFont;
  font-size: 38px;
  font-style: italic;
  text-align: center;
  margin: 0;
  background-image: linear-gradient(to bottom, #f1f1ef, rgba(241, 241, 239, 0.3));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  span {
    font-weight: 700;
    font-style: normal;
  }
}

.background{
  display: none;
}

.get-started {
  background: none;
  border: 1px solid rgba(241, 241, 239, .6);
  font-size: 16px;
  line-height: 1;
  margin: 0.5em;
  padding: .5em 1.2em;
  transition: 0.25s;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  color: $accent;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.5em 0.5em -0.4em $accent;
    border-color: $accent;
    transform: translateY(-0.25em);
  }
}

.marquee {
  display: flex;
  block-size: var(--marquee-item-height);
  margin: 2.5rem auto;
  position: relative;
  overflow-x: hidden;
  mask-image: linear-gradient(
                  to right,
                  hsl(0 0% 0% / 0),
                  hsl(0 0% 0% / 1) 20%,
                  hsl(0 0% 0% / 1) 80%,
                  hsl(0 0% 0% / 0)
  );

  img {
    height: 50px;
  }
}

.marquee--3 {
  --marquee-item-width: 50px;
  --marquee-item-height: 50px;
  --marquee-duration: 20s;
  --marquee-items: 3;
}

.marquee__item {
  --marquee-item-offset: max(
          calc(var(--marquee-item-width) * var(--marquee-items)),
          calc(100% + var(--marquee-item-width))
  );
  --marquee-delay: calc(var(--marquee-duration) / var(--marquee-items) * (var(--marquee-items) - var(--marquee-item-index)) * -1);
  position: absolute;
  inset-inline-start: var(--marquee-item-offset);
  transform: translateX(-50%);
  animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;
}

.marquee--3 .marquee__item:nth-of-type(1) {
  --marquee-item-index: 1;
}

.marquee--3 .marquee__item:nth-of-type(2) {
  --marquee-item-index: 2;
}

.marquee--3 .marquee__item:nth-of-type(3) {
  --marquee-item-index: 3;
}

@keyframes go {
  to {
    inset-inline-start: calc(var(--marquee-item-width) * -1);
  }
}

#home {
  .main-content {
    position: relative;
    z-index: 1;
    color: rgba(241, 241, 239, .6);
    background-size: cover;
    background: url("../assets/backgrounds/Mask group (2).png") no-repeat top left;

    .landing {
      @include grid-container;
      width: 90%;
      max-width: 1200px;
      margin: 4rem auto 0;
      height: calc(100vh - 58px);
      grid-template-columns: 1fr;
      place-items: center;
      align-content: space-evenly;
      gap: 0;
    }

    .description {
      width: 90%;
      max-width: 1200px;
      margin: 58px auto 0;
      background: transparent;
      min-height: calc(90vh - 58px);

      .grid-container {
        @include grid-container;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 'description' 'logo';
        height: 70vh;
        padding-top: 2rem;

        .text {
          font-size: 16px;
          grid-area: description;
        }
      }


    }
  }
}

#pricing {
  .main-content {
    position: relative;
    z-index: 1;
    color: rgba(241, 241, 239, .6);
    width: 95%;
    text-align: center;

    .text {
      font-size: 18px;
    }

    .container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
      margin: 4rem auto 3rem;

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background: url("../assets/backgrounds/Asset 1-8.png") no-repeat;
        background-size: contain;
        z-index: -1;
      }


      .child {
        background: url("../assets/backgrounds/card.svg") transparent;
        border: 1px rgba(232, 78, 80, 0.15) solid;
        border-radius: 20px;
        text-align: left;
        padding: 2rem 1rem;

        .flex {
          display: flex;
          gap: 1rem;
          justify-content: left;

          p {
            margin: .5rem 0;
          }
        }
      }
    }

  }

  .backgrounds {
    position: absolute;
    top: 0;
    opacity: 0.12;
    width: 25%;

    &:nth-of-type(2) {
      right: 0;
    }
  }
}

#services {
  min-height: 100vh;

  .services {
    width: 100%;
    max-width: 1200px;
    margin: 58px auto 0;
    background: transparent;

    .flex-container {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 3rem auto 3rem;
      gap: 2.5rem;
    }

    .card {
      min-width: 350px;
      width: calc( 100% / 3 - 4rem);
      max-width: 450px;
      height: 200px;
      position: relative;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 1.5rem;
      box-shadow: none;
      transition: all 300ms ease;
      cursor: pointer;
      display: inline-block;
      color: white;
      text-align: center;

      &:hover {
        box-shadow: 4px 4px 8px 0 $accent;
        border-color: $accent;
        transform: translateY(-3px);
      }

      .card-content {
        margin: 1rem;
      }

      .more {
        font-size: 16px;
      }

      .sub-title {
        color: $accent;
        font-size: 20px;
        font-weight: 600;
      }
    }


    .icons {
      width: 40px;
      height: 40px;
    }
  }
}

#abouts {
  margin: 4rem 0;

  .container {
    color: white;
    display: grid;
    width: 90%;
    margin: 0 auto;
    place-items: center;
    position: relative;

    .box {
      margin: 2rem auto;

      .images {
        width: 100%;
      }

      .heading {
        margin-bottom: 1.5rem;
      }
    }

    //&:nth-of-type(1) {
    //  width: 50%;
    //
    //  .box:nth-of-type(2) {
    //    display: none;
    //  }
    //}

    &:nth-of-type(2) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: 'description' 'image';

      .box:nth-of-type(1) {
        grid-area: image;
      }
    }

  }

  .text {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
}

#call-to-action {
  position: relative;
  height: 75vh;
  width: 80%;
  margin: 3rem auto;
  border-radius: 25px;
  background-image:  url('../assets/backgrounds/call.svg'), linear-gradient(to bottom, #1f2321, #020b05);
  background-repeat: no-repeat;
  background-position: 45% top;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    display: grid;
    place-items: center;
    gap: 1.25rem;
  }

  .background {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: 75vh;
    object-fit: contain;
    border-radius: 25px;
    object-position: 50% bottom;
  }
}

footer {
  .footer-content {
    padding: 0 5% 1rem;
    color: white;
    font-size: 17px;

    .category {
      font-size: 24px;
    }

    .container {
      grid-template-columns: 1fr;
      justify-items: start;
      display: grid;
      width: 80%;
      margin: 0 auto;
    }

    .column {
      min-width: 245px;
    }

    .navigation {
      list-style: none;
      text-align: left;
      padding-left: 0;
      width: 100%;

      a, a:visited, a:active {
        color: rgba(255, 255, 255, 0.9);
        transition: color 0.2s ease;

        &:hover {
          color: $accent;
        }
      }

      li {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 1rem;
        margin: 0 auto .75rem;

        img {
          width: 26px;
          height: 26px;
          display: block;
        }
      }
    }

    .fieldInput {
      display: flex;
      align-items: center;
      margin: 3rem auto auto;
      width: 100%;
      height: 50px;
      position: relative;
      box-shadow: 4px 4px 8px 0 rgb(0 0 0);
      border-radius: 35px;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.95);
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid rgba(255, 255, 255, 0.2);
      transition: border-color 0.2s ease;

      &:hover, &:focus-within {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }

      .form-input {
        background: none;
        border: none;
        padding: 0 2px;

        &:focus {
          outline: none;
        }
      }

      .form-submit {
        font-size: 12px;
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        height: 38px;
        padding: 0 1.2rem;
        border-radius: 100px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background-color: $accent;
        cursor: pointer;
        color: white;
        transition: all 0.2s ease;

        &:hover {
          box-shadow: 0 0.5em 0.5em -0.4em rgb(0, 0, 0);
        }
      }
    }

    .social_profile {
      margin-top: 40px;

      .list {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: .8rem;
      }

      ul {
        list-style: outside none none;
        margin: 0;
        padding: 0;
      }

      ul li a {
        text-align: center;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        color: $accent;
        border-radius: 5rem;
        width: 50px;
        height: 50px;
        line-height: 3.2rem;
        border: 1px solid rgba(232, 78, 80, 0.3);
        display: block;
      }

      ul li a:hover {
        border: 0;
        box-shadow: 4px 4px 8px 0 $accent;
      }
    }

    .copyright {
      width: 87.5%;
      margin: 1rem auto 0;
      padding-top: 1.5rem;
      font-size: 16px;
      color: #fff;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      text-align: center;
      opacity: 0.8;

      a {
        color: white;
        cursor: pointer;
        transition: all 0.2s ease 0s;

        &:hover {
          color: $accent;
        }
      }
    }
  }
}


@media (min-width: 768px) {
  .header__links {
    font-size: 0.875rem;
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all .2s ease-in-out;

    a {
      position: relative;
      color: rgba(255, 255, 255, 0.65);
      font-weight: 400;
      text-transform: uppercase;
      transition: all 0.15s linear;

      &:hover {
        color: white;
        transform: translateY(-2px);
      }
    }
  }

  .hamburger,
  .header__menu,
  .overlay {
    display: none;
  }

  header {
    .button-link {
      font-size: 15px;
      padding: .5em 1.5em;
      background: $accent;
      border: none;
      border-radius: 50px;
      display: block;
      cursor: pointer;
      color: white;
      transition: all 200ms ease;

      &:hover {
        color: white;
        font-weight: normal;
        transform: translateY(-3px);
      }
    }
  }

  .logo {
    height: 100%;
    align-self: center;
    display: flex;
    align-items: center;
  }

  .nav__list {
    display: flex;
    gap: 3rem;
  }

  .background {
    display: block;
    position: absolute;
    opacity: 0.55;
    top: 0;
    right: 0;
    width: 100%;
  }

  #home {
    .main-content {
      color: rgba(241, 241, 239, .6);
      background: none;

      .landing {
        margin: 58px auto 0;
        gap: 5rem;
        @include grid-container;

        .hero-image {
          height: 80%;
        }
      }

      .description {

        .grid-container {
          grid-template-columns: .80fr .75fr;
          grid-template-rows: 1fr;
          grid-template-areas: 'logo description';
          gap: 2rem;
        }
      }
    }
  }

  #pricing {
    .main-content {
      color: rgba(241, 241, 239, .6);
      background: none;
      margin: 8rem auto 0;



    }
  }

  #abouts {
    .container {
      gap: 3rem;
      grid-template-columns: 1fr 1fr;
      height: 65vh;

      .box {
        padding: 3rem 2rem 0;
      }

      &:nth-of-type(1) {
        .images {
          width: 85%;
        }
      }

      &:nth-of-type(2) {
        grid-template-columns: .75fr 1fr;
        grid-template-areas: 'image description';
        padding-bottom: 4rem;
      }

      &:nth-of-type(3) {
        grid-template-columns: 1fr .75fr;
        padding-left: 6rem;
      }

      .background {
        position: absolute;
        object-fit: contain;
        height: 70vh;
        width: calc(50% - 1.5rem);
      }

      .background.first {
        left: calc(50% - 3rem);
      }

      .background.second {
        left: calc(0% + 3rem);
      }

    }
  }

  #call-to-action {
    border-radius: 75px;

    .center {
      top: 45%;
    }

    .background {
      object-fit: cover;
      border-radius: 75px;
      object-position: 50% 60%;
    }
  }

  footer {
    .footer-content {
      .container {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        justify-items: center;

        .category {
          opacity: 0.85;
          width: 100%;
        }
      }
    }
  }

}